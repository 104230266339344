import Trend3 from '../../../asset/trend3.png'
import Trend4 from '../../../asset/trend4.png'
import Trend5 from '../../../asset/trend5.jpg'
import Pig from '../../../asset/pig.png'
const data =[
    {
        id:1,
        image: Trend3,
        name: "cow",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:2,
        image: Trend4,
        name: "goat",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:3,
        image: Trend5,
        name: "camel",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:4,
        image: Trend4,
        name: "goat",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:5,
        image: Trend5,
        name: "camel",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:6,
        image: Trend3,
        name: "cow",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    }
  ]

export default data