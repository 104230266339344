import machine1 from '../../../asset/machine1.png'
import machine2 from '../../../asset/machine2.png'
import machine3 from '../../../asset/machine3.png'
import machine4 from '../../../asset/machine4.png'
const data =[
    {
        id:1,
        image: machine1,
        name: "tractor",
        price: "200/h",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:2,
        image: machine2,
        name: "tractor",
        price: "200/h",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:3,
        image: machine3,
        name: "tractor",
        price: "200/h",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:4,
        image: machine4,
        name: "tractor",
        price: "200/h",
        rate: 4.5,
        year: "nine years old"
    }
]
export default data;