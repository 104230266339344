import item1 from '../../../asset/item1.png'
import item2 from '../../../asset/item2.png'
import item3 from '../../../asset/item3.png'
import item4 from '../../../asset/item4.png'
import item5 from '../../../asset/item5.png'
import item6 from '../../../asset/item6.png'
const data =[
    {
        id:1,
        image: item1,
        name: "Wheat floor",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:2,
        image: item2,
        name: "Green Wheat",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:3,
        image: item3,
        name: "corn",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:4,
        image: item4,
        name: "Maize",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:5,
        image: item5,
        name: "Gram Seed",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    },
    {
        id:6,
        image: item6,
        name: "Pumpkin Seed",
        price: "200",
        rate: 4.5,
        year: "nine years old"
    }
]
export default data;